export const Urls = {
    BaseURL: 'cdn-base-url',
    Domain: 'https://goodsservices.pplfirst.com',
    FmsURL: '',

    // Client apps
    ppl_participant_connect_url: '/s4s/CustomPage/Index/35',
    ppl_provider_connect_url: '/s4s/CustomPage/Index/34',

    // Organizations
    org_TennCare_id: 'ce287513-4667-463c-89ec-ac17002bfac5',

    // Programs
    program_virginia: '',
    program_tenncare_id: 'd16e5fa2-a316-47f7-89bb-aeb5007dc460',
    program_tenncare_ac_id: 'b4c04c92-458a-4d7d-ad56-aeb5007c6394',
    program_tenncare_ag_id: 'b8914801-503f-4cd1-b155-aeb5007d5459',
    program_tenncare_ecf_id: '3a956c36-9d19-4161-8e48-ae98010c86e3',
    program_tenncare_kb_id: '8d5db84e-a9b9-481b-afd7-ac0c00786eae',
    program_md_dda_id: '01c5ef03-01cf-4c50-aaa5-aeed00e31980',

    // Roles
    role_stateAdmin_id: '4fa0aee3-ff4b-4940-b914-3c0bb36380e2',

    // Page ids
    page_myProviders: '2',
    page_myPrograms: '3',
    page_myDesignatedRep: '4',
    page_mySignatures: '5',
    page_addDesignatedRep: '6',
    page_addProvider: '7',
    page_sagDashboard: '8',
    page_myDetails: '9',
    page_myParticipants: '10',
    page_caseManagerDashboard: '11',
    page_servicesPayrates: '12',
    page_contactUs: '13',
    page_pplConnect: '14',
    page_budgetManagement: '15',
    page_myCourses: '16',
    page_inviteToEnroll: '17',
    page_directProviderReg: '18',
    page_agencyDashboard: '19',
    page_agencyEnrollment: '20',
    page_myRepAgreement: '21',
    page_barCodePage: '22',
    page_pplConnectCreate: '23',
    page_pplConnectMessages: '24',
    page_sagCreateEnrollment: '25',
    page_agencyPaymentDetails: '26',
    page_purchaseRequest: '27',
    page_hireProviderAdmin: '28',

    // Test pages
    page_testJohn: '29',
    page_testKeval: '30',
    page_testTony: '31',
    page_testVijaya: '32',
    page_testWill: '30',

    waivertype_id: '2d666d26-8ade-4d3a-bb82-c1dd3cc59abd',
    waivertype_lah: '7e5bd59d-7f37-4e69-81f5-e2a08c7eb843',
    waivertype_cwp: 'fd17a7cc-679a-433e-95bf-31d55b0f19ce',

    pplConnect_approved: '4ce13d86-df3b-49a5-bdb0-ad040066dec9',
    
    perchaseRequestFormId_OhioRise: '62e170d6416be1818c170a63',

    // Tenncare VSHP referral form
    referral_ten_confirm: 'bf544039-05e1-45bf-a913-e7cbc55f6024',
    referral_ten_vshp_value: '5e4e9176-b560-4593-b532-0ac2582038d6',

    // Tenncare AC referral form
    referral_confirmPgm_ten_ac_quesId: 'bf544039-05e1-45bf-a913-e7cbc55f6024',
    referral_confimPgm_ten_ac_dropDownId: '5e4e9176-b560-4593-b532-0ac2582038d6',
    referral_mCO_ten_ac_quesId: '2e41efda-3a50-43e3-bcc2-5ee57aec5bc4',

    // Tenncare ECF referral form
    referral_confirmPgm_ten_ecf_quesId: 'bf544039-05e1-45bf-a913-e7cbc55f6024',
    referral_confirmPgm_ten_ecf_dropDownId: '5e4e9176-b560-4593-b532-0ac2582038d6',    

    // Tenncare KB referral form
    referral_confirmPgm_ten_kb_quesId: 'd5aed001-585a-4989-b7ff-e32462a8ed1f',
    referral_confirmPgm_ten_kb_dropDownId: '99d62241-ca9b-4701-b40f-fc051b3adab1',
    
    // Tenncare ECF provider referral form
    prReferral_pgmSelection_ten_ecf_quesId: '65f5fb03-5e5c-46cb-a30a-c537dc6629b0',
    prReferral_pgmSelection_ten_ecf_dropDown: '5713af24-d9aa-45f7-8e21-9e541cd49ae8',
   
    // Tenncare KB provider referral form
    prReferral_pgmSelection_ten_kb_quesId: '65f5fb03-5e5c-46cb-a30a-c537dc6629b0',
    prReferral_pgmSelection_ten_kb_dropDown: '59cd6c4d-0ddc-4c5a-8f19-4d08d3a7ecb0',

    // MD DDA provider referral form
    prReferral_waiverSelection_md_dda_quesId: 'aa44842d-ddac-4d9a-8505-946f2a43523a',
    prReferral_waiverSelection_md_dda_dropDown_cpw: '0804d3b5-4a61-4e0a-9e4e-501fe923a7fd',
    prReferral_waiverSelection_md_dda_dropDown_csw: '0c806a30-8ed6-41de-898d-daedef874ec4',
    prReferral_waiverSelection_md_dda_dropDown_fsw: 'b6930f19-be93-45ce-a4df-a6a56852ec51',
    // Ohio Rise MCO
    ohio_rise_mco: 'c372cea3-be0d-4a8c-ac52-aed000859477',

    // TN AC MCO
    tn_ac_mco: 'ebedcdc6-8832-4593-98c1-aec10096636a',
}